import * as React from "react";
import * as ReactDOM from "react-dom";
import { LoginMainComponent } from "./components/LoginMainComponent";

declare global {
    interface Window { renderLoginReact: any; }
}

window.renderLoginReact = function (div, props) {
    let component = React.createElement(LoginMainComponent, props);
    return ReactDOM.render(component, div);
}
export class SfConfigModel {


    _baseURL = undefined;
    GetBaseUrl() {

        if (this._baseURL == undefined) {
            this._baseURL = (window as any).GetBaseUrlFromDocumentMiddleware()
        }
        return this._baseURL;
    }

    _storeFrontId = undefined;
    get_StoreFrontId() {

        if (!this._storeFrontId) {
            if ((window as any).$sfConfigModel) {
                this._storeFrontId = (window as any).$sfConfigModel.STORE_FRONT_ID;
            }
            else if (document.getElementById('hidSfid')) {
                this._storeFrontId = (document.getElementById('hidSfid') as any).value;
            }

        }
        return this._storeFrontId;
    }


    _isRestrictedSite = undefined;
    get_IsRestrictedSite() {

        if (!this._isRestrictedSite) {
            if ((window as any).$sfConfigModel) {
                this._isRestrictedSite = (window as any).$sfConfigModel.IsRestrictedSite;
            }
            else if (document.getElementById('hidIsRestricted')) {
                this._isRestrictedSite = (document.getElementById('hidIsRestricted') as any).value;
            }

        }
        return this._isRestrictedSite;
    }


}

export class ServiceModel{
    postDataPromise(data: any, url:string) {
        if(data ===undefined || data === null){
            data = {};
        }
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "same-origin", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json", // "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                return response
            })
            // .then((result) => {
            //     return result;
            // })
            .catch((error) => {
                console.error(error);
                return error;
            });

    }

    postJSONDataPromise(data: any, url:string) {
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "same-origin", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json", // "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((result) => {
                return result;
            })
            .catch((error) => {
                console.error(error);
                return error;
            });

    }
}
var ValidationMessages = {
    required: "required",
    invalid: "invalid",
    alreadyRegistered: "alreadyRegistered",
    postalMismatch: "postalMismatch",
    selectState: "selectState",
    passwordTooShort: "passwordTooShort",
    passwordTooLong: "passwordTooLong",
    not18YearsOld: "not18YearsOld",
    illegalCharacter: "illegalCharacter",
    passwordMismatch: "passwordMismatch",
    noPostalCode: "noPostalCode"
};

export { ValidationMessages }
export enum ResponseCode {
    None = 0,
    Success = 1,
    Error = 2,
    Exception= 3,
    ValidationError= 4,
    AlreadyRegistered= 5,
    NotLoggedIn= 6,
    BadData= 7,
    Confirm= 8,
    OrderStatusNot1= 9,
    ResponseCode = 10,
    SessionAlreadyRegistered = 11,
    CaptchaError = 12
}
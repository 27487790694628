import { IObserver } from "./IObserver";

export class Observer implements IObserver{
    id: number;    
    context: any;
    name:string;
    public update(suppliedContext) {
        this.context = suppliedContext;
    }


}
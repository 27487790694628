import { ValidationMessages } from "./ValidationMessages";
import { SfCustomerModel } from "./SfCustomerModel";
import { BirthdateModel } from "./BirthdateModel";
import { FieldValidatorModel } from "./FieldValidatorModel";
import { SfCustomerAndFieldsModel } from ".//SfCustomerAndFieldsModel";
import { SfCustomerFieldModel } from "./SfCustomerFieldModel";
import { CustomerFormType } from "./CustomerFormType";

export class LoginValidationService {
    private static readonly TITLE_DDL = "titleddl";
    private static readonly BIRTHDATE_PICKER = "birthdatepicker";
    private static readonly PASSWORD_BOX = "passwordbox";
    private static readonly CONFIRM_PASSWORD_BOX = "confirmpasswordbox";
    private static readonly REGISTRATION = 0;
    private static readonly EDIT_PROFILE = 1;
    private static readonly MIN_PASSWORD_LENGTH = 1;
    private static readonly MAX_PASSWORD_LENGTH = 20;
    private static readonly ILLEGAL_PASSWORD_CHARACTER_LIST = [' '];
    private static readonly VALID_GENDER_VALUES = ['M', 'F', '', null];

    static validateDataSource(dataSourceName: string, dataSoureceObject, objectToValidate, sfCustomer, objectToApplyOn) {
        switch (dataSourceName) {
            case LoginValidationService.BIRTHDATE_PICKER:
                return this.validateBirthDate(objectToValidate, objectToApplyOn);
            case LoginValidationService.PASSWORD_BOX:
                return this.validatePassword(objectToValidate, objectToApplyOn);
            case LoginValidationService.CONFIRM_PASSWORD_BOX:
                return this.validateConfirmPassword(objectToValidate, sfCustomer, objectToApplyOn);
            default:
                return [];
        }
    }
    static validatePassword(password: string, objectToApplyOn: any) {
        var passwordErrorList = [];
        if (password == null) {
            return [];
        }
        if (password.length <= LoginValidationService.MIN_PASSWORD_LENGTH) {
            passwordErrorList.push({
                ValidationMessage: ValidationMessages.passwordTooShort,
                ObjectToApplyOn: objectToApplyOn
            })
        } else if (password.length >= LoginValidationService.MAX_PASSWORD_LENGTH) {
            passwordErrorList.push({
                ValidationMessage: ValidationMessages.passwordTooLong,
                ObjectToApplyOn: objectToApplyOn
            })
        }
        for (var i = 0; i < LoginValidationService.ILLEGAL_PASSWORD_CHARACTER_LIST.length; i++) {
            if (password.indexOf(LoginValidationService.ILLEGAL_PASSWORD_CHARACTER_LIST[i]) != -1) {
                passwordErrorList.push({
                    ValidationMessage: ValidationMessages.illegalCharacter,
                    ObjectToApplyOn: objectToApplyOn
                });
                return passwordErrorList;
            }
        }
        return passwordErrorList;
    }
    static validateConfirmPassword(confirmPassword: string, customer: SfCustomerModel, objectToApplyOn: any) {
        if (confirmPassword !== customer.PASSWORD_TO_BE_SENT) {
            return [
                {
                    ValidationMessage: ValidationMessages.passwordMismatch,
                    ObjectToApplyOn: objectToApplyOn
                }
            ]
        } else {
            return [];
        }
    }
    static validateGender(gender: string) {
        for (var i = 0; i < LoginValidationService.VALID_GENDER_VALUES.length; i++) {
            if (gender == LoginValidationService.VALID_GENDER_VALUES[i]) {
                return true;
            }
        }
        return false;
    }
    static validateBirthDate(birthdate: BirthdateModel, objectToApplyOn: any) {
        if (birthdate == null ||
            birthdate.day == null && birthdate.month == null && birthdate.year == null ||
            birthdate.day != null && birthdate.month != null && birthdate.year != null) {
            return [];
        }
        return [{
            ValidationMessage: ValidationMessages.invalid,
            ObjectToApplyOn: objectToApplyOn
        }];

    }
    static validateDDL(value: string, list: { [key: string]: string }) {
        if (list == null || value == null || value === "") {
            return false;
        }
        if (list[value]) {
            return true;
        }
        return false;
    };
    static validateTitleDDL(title: string, titleList: { [key: string]: string }) {
        return this.validateDDL(title, titleList);
    }
    static customerNgIf(sfCustomerField: SfCustomerFieldModel, sfCustomerForm: SfCustomerAndFieldsModel) {
        return sfCustomerField != null &&
            (sfCustomerForm.sfCustomerFormType === CustomerFormType.REGISTRATION && sfCustomerField.SHOW_ON_REGISTRATION ||
                sfCustomerForm.sfCustomerFormType === CustomerFormType.EDIT_PROFILE && sfCustomerField.SHOW_ON_EDIT_PROFILE ||
                sfCustomerForm.sfCustomerFormType === CustomerFormType.GUEST && sfCustomerField.SHOW_FOR_GUEST ||
                sfCustomerForm.sfCustomerFormType === CustomerFormType.LOGIN &&
                (sfCustomerField.SF_CUSTOMER_FIELD_VALUE === "PASSWORD_TO_BE_SENT" || sfCustomerField.SF_CUSTOMER_FIELD_VALUE === "EMAIL")
            );
    }
    static validate(sfCustomerAndFields: SfCustomerAndFieldsModel, skipDataSources: boolean): FieldValidatorModel[] {
        try {
            var validationErrorList = [], sfCustomerField;
            for (sfCustomerField in sfCustomerAndFields.sfCustomerFields) {
                if (!this.customerNgIf(sfCustomerAndFields.sfCustomerFields[sfCustomerField], sfCustomerAndFields)) {
                    continue;
                }
                if (sfCustomerAndFields.sfCustomerFields[sfCustomerField].IS_REQUIRED && (sfCustomerAndFields.sfCustomer[sfCustomerField] == null || sfCustomerAndFields.sfCustomer[sfCustomerField] === "")) {
                    validationErrorList.push({
                        ValidationMessage: "required",
                        ObjectToApplyOn: sfCustomerAndFields.sfCustomerFields[sfCustomerField].SF_CUSTOMER_FIELD_VALUE
                    });
                } else if (!skipDataSources) {
                    validationErrorList = validationErrorList.concat(this.validateDataSource(sfCustomerAndFields
                        .sfCustomerFields[sfCustomerField].DATA_SOURCE_NAME,
                        sfCustomerAndFields.sfCustomerFields[sfCustomerField].DATA_SOURCE_OBJECT,
                        sfCustomerAndFields.sfCustomer[sfCustomerField],
                        sfCustomerAndFields.sfCustomer,
                        sfCustomerAndFields.sfCustomerFields[sfCustomerField].SF_CUSTOMER_FIELD_VALUE));
                }
            }
            if (validationErrorList.length > 0) {
                try {
                    //TODO: Log
                    //this.LoggerService.Information("Client-side Customer validation failed with Customer:" +
                    //    JSON.stringify(sfCustomerAndFields.sfCustomer) +
                    //    "; and errors:" +
                    //    JSON.stringify(validationErrorList));
                } catch (exception) {
                    //TODO: Log
                    //this.LoggerService.Information("Customer validation failed but there was an exception logging validation errors: ");
                }
            }
            return validationErrorList;
        }
        catch (exception) {
            //TODO: LOg
            //this.LoggerService.Error("Client-side error when validating SF Customer:" + exception.stack.toString())
            return [];
        }
    }
}
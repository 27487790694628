export class SignInModel{
    EMAIL:SignInProperty = new SignInProperty("EMAIL",true);
    PASSWORD:SignInProperty = new SignInProperty("PASSWORD", true);
    KeepMeSignedIn:SignInProperty = new SignInProperty("KeepMeSignedIn", false, true);
    loginError:boolean = false;
}
export class SignInProperty{
    constructor(name:string, isRequired:boolean=true, suppliedValue:any=undefined){
        this.NAME =name;
        this.IsRequired = isRequired;
        this.value = suppliedValue;
    }
    NAME:string;
    HasError:boolean= false;
    ErrorMessage:string;
    value:any;
    IsRequired:boolean;
}

export class ForgotPassModel{
    EMAIL:SignInProperty = new SignInProperty("EMAIL",true);
    loginError:boolean = false;
    responseMessage:string=undefined;
    requestSubmitted:boolean= false;
}